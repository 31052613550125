import { Injectable } from '@angular/core';

import { ContextService } from '../../services';

@Injectable()
export class ThirdLevelFormDeactivateGuard  {

    constructor(
        private contextService: ContextService
    ) { }

    canDeactivate(): boolean {
        this.contextService.unsetThirdLevelElement();
        return true;
    }
}